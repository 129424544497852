import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, ListItem, FontIcon, Divider } from 'react-md'
import { push } from 'connected-react-router'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Img } from 'react-image'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as dimensionsActions from 'state/dimensionsActions'
import * as appUIActions from 'state/ui/app-ui-actions'
import * as appActions from 'state/appActions'

import routes from '../../routes'
import { financeRoutes } from '../ManagerFinance/index'
import ManagerDrawerContextList from './ManagerDrawerContextList'
import WithQueryParams from 'ui/components/WithQueryParams'
import {
	CardImgSkeletonAbsolute,
	CardImgSkeletonAbsoluteLoading,
} from 'ui/StyledComponents/Cards'
import {
	DrawerLogoContainer,
	DrawerMenuItemText,
	DrawerMenuScrollContainer,
	DrawerMenuLogo,
	DrawerMenuItemSubtitle,
} from './styles'
import ViiluLogo from 'svg/viilu-logo-white.svg'
import WithSelectedCompany from 'ui/components/WithSelectedCompany'

class AppDrawer extends Component {
	state = {
		customerItemsExpanded: true,
		financeItemsExpanded: false,
		internalItemsExpanded: false,
	}

	componentDidMount = () => {
		const {
			dimensions: { isDesktop },
			_setNavDrawerVisible,
			_setSelectedCompany,
			_setSelectedCustomerCompany,
			drawerVisible,
		} = this.props

		if (drawerVisible === null) {
			_setNavDrawerVisible(isDesktop)
		}

		const ensureManagerCompany = () => {
			const { selectedManager, selectedCompany } = this.props
			// Set the company to the "ghost company" of this manager
			if (selectedManager && selectedManager.active) {
				const ghostCompanyUUID = selectedManager.uuid
				if (!selectedCompany || selectedCompany.uuid !== ghostCompanyUUID) {
					if (selectedCompany && !selectedCompany.hiddenFromListing) {
						window.setTimeout(() => {
							_setSelectedCustomerCompany({
								uuid: selectedCompany.uuid,
								urlName: selectedCompany.urlName,
							})
							window.setTimeout(() => {
								_setSelectedCompany({ uuid: ghostCompanyUUID, urlName: null })
							}, 50)
						}, 50)
					} else {
						window.setTimeout(() => {
							_setSelectedCompany({ uuid: ghostCompanyUUID, urlName: null })
						}, 50)
					}
				}
			}
		}

		const managerCompanyInterval = window.setInterval(() => {
			const { selectedManager } = this.props
			if (selectedManager) {
				window.clearInterval(managerCompanyInterval)
				ensureManagerCompany()
			}
		}, 50)
	}

	openMenuItem = (path) => {
		const {
			_push,
			dimensions: { isDesktop },
			_setNavDrawerVisible,
		} = this.props

		// TODO: Can this be less hacky?
		if (!isDesktop) {
			_setNavDrawerVisible(false)
		}

		_push(path)
	}

	renderItems = (route) => {
		const { auth, currentRoute, selectedManager, intl } = this.props
		if (route.hidden || route.category !== 'manager') return null

		if (
			selectedManager &&
			selectedManager.featuresHiddenManager &&
			selectedManager.featuresHiddenManager[route.feature]
		) {
			return null
		}

		let strikethrough = false
		if (
			!selectedManager ||
			(route.feature &&
				selectedManager &&
				selectedManager.featuresManager &&
				!selectedManager.featuresManager[route.feature])
		) {
			strikethrough = true
		}

		let internalToolsAllowed = false
		if (
			selectedManager &&
			selectedManager.uuid === '0e1d6dc2-c34b-4b56-a159-789d71bae025'
		) {
			// Viilu Solutions Oy
			internalToolsAllowed = true
		}

		if (route.internal && !internalToolsAllowed) {
			return null
		}

		if (
			route.restricted &&
			(!internalToolsAllowed ||
				!auth ||
				auth.uid !== 'Ug9R58TYZLXRP1Gxik0pUDSaAgl1')
		) {
			return null
		}

		const disabled = !!(
			(auth.isEmpty && !route.public) ||
			route.disabled ||
			strikethrough
		)

		const current = route.path === currentRoute.path
		return (
			<ListItem
				key={'menuItem-' + route.path}
				leftIcon={
					<FontIcon
						style={{ color: 'rgba(255,255,255,0.5)', marginRight: -16 }}
					>
						{route.icon}
					</FontIcon>
				}
				style={{
					background: current
						? route.internal
							? 'var(--color-secondary-dark)'
							: 'rgba(0,0,0,0.15)'
						: route.internal
						? 'var(--color-secondary)'
						: undefined,
				}}
				primaryText={
					<DrawerMenuItemText>
						{typeof route.title === 'function'
							? route.title(intl)
							: route.title}
					</DrawerMenuItemText>
				}
				primaryTextStyle={
					disabled
						? {
								textDecoration: 'line-through',
								fontWeight: 400,
								color: 'white',
								opacity: 0.3,
						  }
						: {
								color: 'white',
						  }
				}
				onClick={() => this.openMenuItem(route.relativePath || route.path)}
				disabled={disabled}
			/>
		)
	}

	getCategoryRoutes = (routes, category) => {
		const { selectedManager } = this.props

		return routes.filter((route) => {
			if (route.hidden || route.category !== category) {
				return false
			}

			if (
				selectedManager &&
				selectedManager.featuresHiddenManager &&
				selectedManager.featuresHiddenManager[route.feature]
			) {
				return false
			}

			return true
		})
	}

	getRouteItem = (route, category) => {
		const { selectedManager, auth, currentRoute, intl } = this.props
		// accounting auditors have access to docs only
		const disabled = !!(
			(auth.isEmpty && !route.public) ||
			!selectedManager ||
			!selectedManager.uuid ||
			route.disabled ||
			(selectedManager.featuresManager &&
				!selectedManager.featuresManager[route.feature])
		)
		const current = route.path === currentRoute.path

		return (
			<ListItem
				key={'menuItem-' + route.path}
				leftIcon={
					route.icon ? (
						<FontIcon
							style={{ color: 'rgba(255,255,255,0.5)', marginRight: -16 }}
						>
							{route.icon}
						</FontIcon>
					) : undefined
				}
				style={{ background: current ? 'rgba(0,0,0,0.15)' : undefined }}
				primaryText={
					<DrawerMenuItemText>
						{typeof route.title === 'function'
							? route.title(intl)
							: route.title}
					</DrawerMenuItemText>
				}
				primaryTextStyle={
					disabled
						? {
								textDecoration: 'line-through',
								fontWeight: 400,
								color: 'white',
								opacity: 0.3,
						  }
						: {
								color: 'white',
						  }
				}
				onClick={() => this.openMenuItem(route.relativePath || route.path)}
				disabled={disabled}
			/>
		)
	}

	renderCustomerItems = (routes, navStyle) => {
		const { intl } = this.props
		const { customerItemsExpanded } = this.state

		const customerRoutes = this.getCategoryRoutes(routes, 'manager-customer')

		const customerItems = customerRoutes
			.map((route) => this.getRouteItem(route, 'manager-customer'))
			.filter((item) => item)

		if (customerItems.length === 0) {
			return null
		}

		return (
			<ListItem
				key="menuItem-manager-customer"
				primaryText={
					<DrawerMenuItemSubtitle>
						<FormattedMessage
							defaultMessage="Asiakkaat"
							description="Button that allows the user to open the customer tools section of the app."
						/>
					</DrawerMenuItemSubtitle>
				}
				secondaryText={
					customerItemsExpanded
						? undefined
						: customerRoutes
								.map((r) =>
									typeof r.title === 'function' ? r.title(intl) : r.title,
								)
								.join(', ')
				}
				secondaryTextStyle={{
					color: customerItemsExpanded ? 'transparent' : 'white',
				}}
				nestedItems={customerItems}
				defaultVisible
				nestedListStyle={{
					...navStyle,
					paddingLeft: 0,
				}}
				onClick={() =>
					this.setState({ customerItemsExpanded: !customerItemsExpanded })
				}
				style={{ marginTop: 24 }}
			/>
		)
	}

	renderFinanceItems = (routes, navStyle) => {
		const { intl, selectedManager } = this.props
		const { financeItemsExpanded } = this.state

		if (
			!selectedManager ||
			!selectedManager.featuresManager ||
			!selectedManager.featuresManager['finance']
		)
			return null

		const customerRoutes = this.getCategoryRoutes(routes, 'finance')
		const financeItems = routes
			.map((route) => this.getRouteItem(route, 'finance'))
			.filter((item) => item)

		if (financeItems.length === 0) {
			return null
		}

		return (
			<ListItem
				key="menuItem-manager-finance"
				primaryText={
					<DrawerMenuItemSubtitle>
						<FormattedMessage
							defaultMessage="Talous"
							description="Button that allows the user to open the finance tools section of the app."
						/>
					</DrawerMenuItemSubtitle>
				}
				secondaryText={
					financeItemsExpanded
						? undefined
						: customerRoutes
								.map((r) =>
									typeof r.title === 'function' ? r.title(intl) : r.title,
								)
								.join(', ')
				}
				secondaryTextStyle={{
					color: financeItemsExpanded ? 'transparent' : 'white',
				}}
				nestedItems={financeItems}
				defaultVisible={false}
				nestedListStyle={{
					...navStyle,
					paddingLeft: 0,
				}}
				onClick={() =>
					this.setState({ financeItemsExpanded: !financeItemsExpanded })
				}
				style={{ marginTop: 24 }}
			/>
		)
	}

	renderInternalItems = (routes, navStyle) => {
		const { intl } = this.props
		const { internalItemsExpanded } = this.state

		const internalRoutes = this.getCategoryRoutes(routes, 'manager-internal')

		const internalItems = internalRoutes.map((route) =>
			this.getRouteItem(route, 'manager-internal'),
		)

		return (
			<ListItem
				key="menuItem-manager-internal"
				primaryText={
					<DrawerMenuItemSubtitle>
						<FormattedMessage
							defaultMessage="Sisäiset"
							description="Button that allows the user to open the internal tools section of the app."
						/>
					</DrawerMenuItemSubtitle>
				}
				secondaryText={
					internalItemsExpanded
						? undefined
						: internalRoutes
								.map((r) =>
									typeof r.title === 'function' ? r.title(intl) : r.title,
								)
								.join(', ')
				}
				secondaryTextStyle={{
					color: internalItemsExpanded ? 'transparent' : 'white',
				}}
				nestedItems={internalItems}
				defaultVisible={false}
				nestedListStyle={{
					...navStyle,
					paddingLeft: 0,
				}}
				onClick={() =>
					this.setState({ internalItemsExpanded: !internalItemsExpanded })
				}
				style={{ marginTop: 24 }}
			/>
		)
	}

	render() {
		const {
			dimensions: { isDesktop },
			selectedManager,
			queryParams: { experience, e },
			drawerVisible,
			_setNavDrawerVisible,
		} = this.props

		if (experience === 'lean' || e === 'l') {
			return null
		}

		const navStyle = {
			background: '#385167',
			margin: 0,
			listStyleType: 'none',
		}

		if (isDesktop && drawerVisible) {
			navStyle.position = 'relative'
		}

		let headerItem = [
			<DrawerLogoContainer key="company-drawer-menu-logo">
				<a
					href="https://vii.lu"
					style={{ display: 'flex', textDecoration: 'none' }}
				>
					<DrawerMenuLogo />
					<img src={ViiluLogo} alt="viilu" width={60} height={30} />
				</a>
				{isDesktop ? (
					<div
						className="flex-center margin-left margin-right"
						onClick={() => _setNavDrawerVisible(false)}
						style={{
							background: 'rgba(0,0,0,0.1)',
							borderRadius: 8,
							borderColor: 'rgba(0,0,0,0.1)',
							borderWidth: 1,
							borderStyle: 'solid',
							cursor: 'pointer',
						}}
					>
						<FontIcon style={{ color: 'white' }}>chevron_left</FontIcon>
					</div>
				) : (
					<div
						className="flex-center margin-left margin-right"
						onClick={() => {
							if (!isDesktop) {
								_setNavDrawerVisible(false)
							}
						}}
						style={{
							position: 'absolute',
							right: 0,
							top: 20,
							background: 'rgba(0,0,0,0.2)',
							borderRadius: 8,
							borderColor: 'rgba(0,0,0,0.1)',
							borderWidth: 1,
							borderStyle: 'solid',
							cursor: 'pointer',
							height: 24,
							zIndex: 100,
						}}
					>
						<FontIcon style={{ color: 'white' }}>close</FontIcon>
					</div>
				)}
			</DrawerLogoContainer>,
		]

		if (selectedManager) {
			const {
				name,
				logo,
				drawerLogo,
				drawerLogoAlignment = 'center',
				whitelabelManager: whitelabel,
			} = selectedManager

			if (whitelabel && (logo || drawerLogo)) {
				const { fileURL } = drawerLogo || logo
				headerItem = [
					<div key="menuItem-header" style={{ position: 'relative' }}>
						{!fileURL ? null : (
							<div
								style={{
									width: 'calc(100% + 64px)',
									background: 'white',
									display: 'flex',
									marginLeft: drawerLogoAlignment === 'center' ? -32 : 0,
									marginRight: drawerLogoAlignment === 'center' ? -32 : 0,
									marginTop: 0,
									height: 56,
								}}
							>
								<Img
									alt={name}
									src={fileURL}
									loader={
										<CardImgSkeletonAbsolute>
											<CardImgSkeletonAbsoluteLoading />
										</CardImgSkeletonAbsolute>
									}
									unloader={
										<CardImgSkeletonAbsolute>
											<p className="text-subtle">Kuvaa ei löydy</p>
										</CardImgSkeletonAbsolute>
									}
									style={{
										height: 56,
										maxWidth: '100%',
										marginLeft: drawerLogoAlignment === 'center' ? 'auto' : 0,
										marginRight: drawerLogoAlignment === 'center' ? 'auto' : 0,
									}}
								/>
								{isDesktop ? (
									<div
										className="flex-center margin-left margin-right"
										onClick={() => _setNavDrawerVisible(false)}
										style={{
											position: 'absolute',
											right: 0,
											top: 20,
											background: 'rgba(0,0,0,0.2)',
											borderRadius: 8,
											borderColor: 'rgba(0,0,0,0.1)',
											borderWidth: 1,
											borderStyle: 'solid',
											cursor: 'pointer',
											height: 22,
										}}
									>
										<FontIcon style={{ color: 'white' }}>chevron_left</FontIcon>
									</div>
								) : (
									<div
										className="flex-center margin-left margin-right"
										onClick={() => {
											if (!isDesktop) {
												_setNavDrawerVisible(false)
											}
										}}
										style={{
											position: 'absolute',
											right: 0,
											top: 20,
											background: 'rgba(0,0,0,0.2)',
											borderRadius: 8,
											borderColor: 'rgba(0,0,0,0.1)',
											borderWidth: 1,
											borderStyle: 'solid',
											cursor: 'pointer',
											height: 24,
											zIndex: 100,
										}}
									>
										<FontIcon style={{ color: 'white' }}>close</FontIcon>
									</div>
								)}
							</div>
						)}
					</div>,
				]
			}
		}

		const managerRoutes = routes.filter((r) => r.category === 'manager')
		const managerItems = managerRoutes
			.map(this.renderItems)
			.concat(<Divider key={'manager-divider'} />)
		let menuItems = headerItem
			.concat(managerItems)
			.concat(this.renderCustomerItems(routes, navStyle))
			.concat(this.renderFinanceItems(financeRoutes, navStyle))
			.concat(this.renderInternalItems(routes, navStyle))

		return (
			<Drawer
				visible={drawerVisible}
				onVisibilityChange={() => {}}
				desktopType={Drawer.DrawerTypes.FULL_HEIGHT}
				tabletType={Drawer.DrawerTypes.TEMPORARY}
				mobileType={Drawer.DrawerTypes.TEMPORARY}
				navStyle={navStyle}
				style={{ ...navStyle }}
			>
				<div
					className="flex-column"
					style={{ justifyContent: 'space-between', height: '100%' }}
				>
					<DrawerMenuScrollContainer>{menuItems}</DrawerMenuScrollContainer>
					<ManagerDrawerContextList selectedManager={selectedManager} />
				</div>
			</Drawer>
		)
	}
}

AppDrawer.propTypes = {
	auth: PropTypes.object.isRequired,
	currentRoute: PropTypes.object.isRequired,
}

const mapState = ({
	ui: {
		app: { drawerVisible },
	},
	dimensions,
	router,
	firebaseReducer: { auth },
}) => ({
	drawerVisible,
	dimensions,
	router,
	auth,
})

const mapDispatchToProps = (dispatch) => ({
	_push: bindActionCreators(push, dispatch),
	_updateDimensions: bindActionCreators(
		dimensionsActions.updateDimensions,
		dispatch,
	),
	_setNavDrawerVisible: bindActionCreators(
		appUIActions._setNavDrawerVisible,
		dispatch,
	),
	_setSelectedCompany: bindActionCreators(
		appActions._setSelectedCompany,
		dispatch,
	),
	_setSelectedCustomerCompany: bindActionCreators(
		appActions._setSelectedCustomerCompany,
		dispatch,
	),
})

export default compose(
	injectIntl,
	WithQueryParams,
	WithSelectedCompany,
	connect(mapState, mapDispatchToProps),
)(AppDrawer)
